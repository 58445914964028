import React from 'react'
import './Layout.scss';
import { Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link } from 'react-router-dom';
const Sidebar = () => {
  return (
    <div>
      <Menu className='side-menu'>
        <div>
          <div className="brand-name-sidebar"><span>Pharm</span><span>Nex</span></div>
        </div>
        <span className='menu-items'>
        <MenuItem> Dashboard </MenuItem>
        <MenuItem> Customers </MenuItem>
        <MenuItem> Invoices </MenuItem>
        <MenuItem> Transactions </MenuItem>
        <MenuItem> Refunds </MenuItem>
        <MenuItem> Reports </MenuItem>
        <MenuItem className='active'>  <Link to="/app/setting/subscriptionDetails" style={{color: '#fff',textDecoration: 'none'}}> Settings </Link></MenuItem>

        {/* <SubMenu label="Inventory">
          <MenuItem> Product </MenuItem>
          <MenuItem> Groups </MenuItem>
          <MenuItem> Adjustments </MenuItem>
        </SubMenu>
        <SubMenu label="Sales">
          <MenuItem> Customers </MenuItem>
          <MenuItem> Doctors </MenuItem>
          <MenuItem> Invoices </MenuItem>
          <MenuItem> Payments Received </MenuItem>
          <MenuItem> Sales Return </MenuItem>
          <MenuItem> Credit Notes </MenuItem>
          <MenuItem> Invoices </MenuItem>
        </SubMenu>
        <SubMenu label="Purchases">
          <MenuItem> Purchases1 </MenuItem>
          <MenuItem> Purchases2 </MenuItem>
        </SubMenu>
        <SubMenu label="Reports">
          <MenuItem> Reports1 </MenuItem>
          <MenuItem> Reports2 </MenuItem>
        </SubMenu> */}
        </span>

      </Menu>

    </div>
  )
}
export default Sidebar;