
// import React, { useEffect, useRef, useState } from "react";
// import './OTPVerify.scss';
// import Button from "../../../component/ui/Button";
// import otpverify from '../../../assets/image/otpVerify.svg';
// import timer from '../../../assets/image/timer.svg';
// import OTPInput from "react-otp-input";
// import { useLocation, useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
// import { decodeTokenToEmail, encodeEmailToToken } from "../../../utils/controller";
// import { user_otp_verification, user_send_otp } from "../../../store/action/auth";
// import { useDispatch } from "react-redux";

// const OTPVerify = () => {
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const location = useLocation();
//   const [timeLeft, setTimeLeft] = useState(5 * 60);
//   const [otp, setOtp] = useState('');
//   const [email, setEmail] = useState('');
//   const [error, setError] = useState({});
//   const otpRefs = useRef([]);

//   useEffect(() => {
//     const query = new URLSearchParams(location.search);
//     const token = query.get('token');
//     if (token) {
//       const decodedEmail = decodeTokenToEmail(token);
//       console.log('decodedEmail', decodedEmail);
//       setEmail(decodedEmail);
//     }
//   }, [location.search]);

//   useEffect(() => {
//     if (timeLeft <= 0) return;
//     const intervalId = setInterval(() => {
//       setTimeLeft(prevTime => prevTime - 1);
//     }, 1000);

//     return () => clearInterval(intervalId);
//   }, [timeLeft]);

//   useEffect(() => {
//     if (otpRefs.current[0]) {
//       otpRefs.current[0].focus();
//     }
//   }, []);

//   const formatTime = (seconds) => {
//     const minutes = Math.floor(seconds / 60);
//     const remainingSeconds = seconds % 60;
//     return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
//   };

//   const handleOtpChange = (otp) => {
//     setOtp(otp);
//     const nextInputIndex = otp.length;
//     if (nextInputIndex < otpRefs.current.length) {
//       otpRefs.current[nextInputIndex].focus();
//     }
//   };

//   const hanndleVerifyOTP = () => {
//     let body = {
//       otp: otp,
//       email: email,
//     };
//     dispatch(user_otp_verification(body, location.pathname === '/otpVerify/resetpassword' ? "" : '?verify_email=true')).then((res) => {
//       if (res?.success) {
//         toast.success(res?.message);
//         if (location.pathname === '/otpVerify/resetpassword') {
//           const token = encodeEmailToToken(email);
//           navigate(`/createNewPassword/?token=${token}`);
//         } else {
//           navigate('/organizationDetail');
//         }
//       } else {
//         toast.error(res?.message);
//         setError(res?.message);
//       }
//     });
//   };

//   const handleResendOTP = () => {
//     setOtp('')
//     let body = {
//       email: email,
//     };
//     if (timeLeft === 0) {
//       dispatch(user_send_otp(body)).then((res) => {
//         if (res?.success) {
//           toast.success(res?.message);
//           setTimeLeft(5 * 60);
//         }
//       });
//     } else {
//       toast.error('Please wait for the timer to end.');
//     }
//   };

//   return (
//     <div className='otpverify-form'>
//       <div className="otpverify-body row clearfix">
//         <div className="left-section">
//           <div className="illustration">
//             <div className="brand-name"><span>Pharma</span><span>Nex</span></div>
//             <div className="otpverify-illustration"><img src={otpverify} alt="" /></div>
//           </div>
//         </div>
//         <div className="right-section">
//           <form className="form-body py-4 ms-0">
//             <h1 className="form-title">Enter OTP</h1>
//             <p className="mt-5 mb-3 text-center font-bold">Verify your email address</p>
//             <p className="mt-4 mb-3 text-center">Check the OTP sent to your email address</p>
//             <p className="mt-4 mb-3 text-center">{email}<a href="/forgotPassword" className="font-bold ms-3"> Change</a></p>
//             <span className="enter-otp my-5 py-3">
//               <OTPInput
//                 value={otp}
//                 onChange={handleOtpChange}
//                 numInputs={4}
//                 renderSeparator={<span></span>}
//                 renderInput={(props, index) => (
//                   <input
//                     {...props}
//                     ref={el => otpRefs.current[index] = el}
//                   />
//                 )}
//               />
//             </span>
//             <Button type="button" button="Verify OTP" onClick={hanndleVerifyOTP} disabled={otp.length !== 4}></Button>
//             <div className="timer-body"><span className="timer"><img className="align-top me-2" src={timer} alt="timer"/>{formatTime(timeLeft)}</span></div>
//             {timeLeft === 0 && <p className="mt-5 mb-3 text-center">Didn’t receive the code?  <span className="primary-color cursor-pointer"><a className="font-bold" onClick={handleResendOTP}> Resend Code</a></span></p>}
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default OTPVerify;

import React, { useEffect, useRef, useState } from "react";
import './OTPVerify.scss';
import Button from "../../../component/ui/Button";
import otpverify from '../../../assets/image/otpVerify.svg';
import timer from '../../../assets/image/timer.svg';
import OTPInput from "react-otp-input";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { decodeTokenToEmail, encodeEmailToToken } from "../../../utils/controller";
import { user_otp_verification, user_send_otp } from "../../../store/action/auth";
import { useDispatch } from "react-redux";

const OTPVerify = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [timeLeft, setTimeLeft] = useState(5 * 60);
  const [otp, setOtp] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState({});
  const otpRefs = useRef([]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const token = query.get('token');
    if (token) {
      const decodedEmail = decodeTokenToEmail(token);
      console.log('decodedEmail', decodedEmail);
      setEmail(decodedEmail);
    }
  }, [location.search]);

  useEffect(() => {
    if (timeLeft <= 0) return;
    const intervalId = setInterval(() => {
      setTimeLeft(prevTime => prevTime - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  useEffect(() => {
    if (otpRefs.current[0]) {
      otpRefs.current[0].focus();
    }
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        hanndleVerifyOTP();
      }
    };
    
    window.addEventListener('keydown', handleKeyDown);
    
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [otp, email]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const handleOtpChange = (otp) => {
    setOtp(otp);
    const nextInputIndex = otp.length;
    if (nextInputIndex < otpRefs.current.length) {
      otpRefs.current[nextInputIndex].focus();
    }
  };

  const hanndleVerifyOTP = () => {
    console.log('Handle Clicked')
    let body = {
      otp: otp,
      email: email,
    };
    dispatch(user_otp_verification(body, location.pathname === '/otpVerify/resetpassword' ? "?verify_email=false" : '?verify_email=true')).then((res) => {
      if (res?.success) {
        toast.success(res?.message);
        if (location.pathname === '/otpVerify/resetpassword') {
          const token = encodeEmailToToken(email);
          navigate(`/createNewPassword/?token=${token}`);
        } else {
          navigate('/organizationDetail');
        }
      } else {
        console.log(res?.error)
        toast.error(res?.message);
        setError(res?.message);
      }
    });
  };

  const handleResendOTP = () => {
    setOtp('');
    let body = {
      email: email,
    };
    if (timeLeft === 0) {
      dispatch(user_send_otp(body)).then((res) => {
        if (res?.success) {
          toast.success(res?.message);
          setTimeLeft(5 * 60);
          if (otpRefs.current[0]) {
            otpRefs.current[0].focus();
          }
        }
      });
    } else {
      toast.error('Please wait for the timer to end.');
    }
  };

  return (
    <div className='otpverify-form'>
      <div className="otpverify-body row clearfix">
        <div className="left-section">
          <div className="illustration">
            <div className="brand-name"><span>Pharm</span><span>Nex</span></div>
            <div className="otpverify-illustration"><img src={otpverify} alt="" /></div>
          </div>
        </div>
        <div className="right-section">
          <form className="form-body py-4 ms-0">
            <h1 className="form-title">Enter OTP</h1>
            <p className="mt-5 mb-3 text-center font-bold">Verify your email address</p>
            <p className="mt-4 mb-3 text-center">Check the OTP sent to your email address</p>
            <p className="mt-4 mb-3 text-center">{email}<a href="/forgotPassword" className="font-bold ms-3"> Change</a></p>
            <span className="enter-otp my-5 py-3">
              <OTPInput
                value={otp}
                onChange={handleOtpChange}
                numInputs={4}
                renderSeparator={<span></span>}
                renderInput={(props, index) => (
                  <input
                    {...props}
                    ref={el => otpRefs.current[index] = el}
                  />
                )}
              />
            </span>
            <Button type="button" button="Verify OTP" onClick={hanndleVerifyOTP} disabled={otp.length !== 4}></Button>
            <div className="timer-body"><span className="timer"><img className="align-top me-2" src={timer} alt="timer"/>{formatTime(timeLeft)}</span></div>
            {timeLeft === 0 && <p className="mt-5 mb-3 text-center">Didn’t receive the code?  <span className="primary-color cursor-pointer"><a className="font-bold" onClick={handleResendOTP}> Resend Code</a></span></p>}
          </form>
        </div>
      </div>
    </div>
  );
};

export default OTPVerify;

