import { createBrowserRouter } from "react-router-dom";
import Login from "../pages/auth/login/Login";
import ForgotPassword from "../pages/auth/forgotpassword/ForgotPassword";
import Signup from "../pages/auth/signup/Signup";
import CreateNewPassword from "../pages/auth/createNewPassword/CreateNewPassword";
import OTPVerify from "../pages/auth/otpVerify/OTPVerify";
import Layout from "../layout/Layout";
import Dashboard from "../pages/dashboard";
import PrivateRoute from "./PrivateRoutes";
import Settinglayout from "../layout/Settinglayout";
import Subscription from "../pages/subscriptionp";
import PlanDetails from "../pages/subscriptionp/PlanDetails";

export const Approuter = createBrowserRouter([
    {
        path: "/",
        element: <Login />
    },
    {
        path: "/forgotPassword",
        element: <ForgotPassword />
    },
    {
        path: "/createNewPassword",
        element: <CreateNewPassword />
    },
    {
        path: "/otpVerify/*",
        element: <OTPVerify />
    },
    {
        path: "/app",
        element: <Layout />,
        children: [
            {
                path: "/app/dashboard",
                element: <PrivateRoute element={<Dashboard />} />
            },


            {
                path: "/app/setting",
                element: <Settinglayout />,
                children: [
                    {
                        path: "/app/setting/subscription",
                        
                        element: <PrivateRoute element={<Subscription />} />
                    },
                    {
                        path: "/app/setting/subscriptionDetails",
                        element: <PrivateRoute element={<PlanDetails />} />
                    }
                ]
            },
        ]
    },
])



