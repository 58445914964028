import React, { useState } from 'react'
import './AddSubscription.scss';
import UnderCunstruction from '../../underCunstruction';
import Button from '../../../component/ui/Button';
import InputField from '../../../component/form/FormInput';
import SettingnavBar from '../../../layout/SettingnavBar';
import SettingFooter from '../../../layout/SettingFooter';

const AddSubscription = () => {


    const [planName, setplanName] = useState()
    const [displayTitle, setdisplayTitle] = useState()
    const [monthlyCost, setmonthlyCost] = useState()
    const [annualCost, setannualCost] = useState()



    return (
        <div className="add-sub-wrapper">
            <SettingnavBar />

            <div style={{ height: 'calc(100vh - 120px)', overflow: 'auto', padding: '1.2rem' }}>
                <div className='w-75'>
                    <p className='plan-details-title'>Plan Details</p>
                    <div className="row me-5 pe-5">
                        <div className="col-6">
                            <InputField
                                type="text"
                                placeholder="Enter your plan name"
                                label="Plan Name"
                                onChange={(value) => setplanName(value)}
                                value={planName}
                                name={'planName'}
                                //error={error?.organization_name}
                                autoFocus={true}
                            />
                        </div>
                        <div className="col-6">
                            <InputField
                                type="text"
                                placeholder="Enter your plan name"
                                label="Display Title"
                                onChange={(value) => setdisplayTitle(value)}
                                value={displayTitle}
                                name={'displayTitle'}
                                //error={error?.organization_name}
                                autoFocus={false}
                            />
                        </div>
                        <div className="col-6">
                            <InputField
                                type="text"
                                placeholder="Enter cost"
                                label="Monthly Cost"
                                onChange={(value) => setmonthlyCost(value)}
                                value={monthlyCost}
                                name={'monthlyCost'}
                                //error={error?.organization_name}
                                autoFocus={false}
                            />
                        </div>
                        <div className="col-6">
                            <InputField
                                type="text"
                                placeholder="Enter cost"
                                label="Annual Cost"
                                onChange={(value) => setannualCost(value)}
                                value={annualCost}
                                name={'annualCost'}
                                //error={error?.organization_name}
                                autoFocus={false}
                            />
                        </div>
                    </div>

                    <div>
                        <p className='plan-details-title'>Features</p>
                        <div className='sub-plan-accordion'>
                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            Core Feature
                                        </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <div className="settings-container">
                                                {/* plan detail row */}
                                                <div className="setting-row">
                                                    <span className='water-mark'>
                                                        <div className="setting-label">Watermark</div>
                                                        <div className="setting-input">
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" value="" id="access" />
                                                                <label className="form-check-label" for="access">
                                                                    Enable
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </span>
                                                </div>
                                                <div className="setting-row">
                                                    <div className="setting-label">Sales Orders</div>
                                                    <div className="setting-input">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" id="access" />
                                                            <label className="form-check-label" for="access">
                                                                Unlimited
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <span className='checkbox-n-count'>
                                                        <span className='max-count'>
                                                            <span className='count'>Max Count</span>
                                                            <input className="sub-plan-input" type="text" value="646" />
                                                        </span>
                                                    </span>
                                                </div>


                                                <div className="setting-row">
                                                    <div className="setting-label">Invoices</div>
                                                    <div className="setting-input">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" id="access" />
                                                            <label className="form-check-label" for="access">
                                                                Unlimited
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <span className='checkbox-n-count'>
                                                        <span className='max-count'>
                                                            <span className='count'>Max Count</span>
                                                            <input className="sub-plan-input" type="text" value="646" />
                                                        </span>
                                                    </span>
                                                </div>

                                                <div className="setting-row">
                                                    <div className="setting-label">Purchase Orders</div>
                                                    <div className="setting-input">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" id="access" />
                                                            <label className="form-check-label" for="access">
                                                                Unlimited
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <span className='checkbox-n-count'>
                                                        <span className='max-count'>
                                                            <span className='count'>Max Count</span>
                                                            <input className="sub-plan-input" type="text" value="646" />
                                                        </span>
                                                    </span>
                                                </div>
                                                <div className="setting-row">
                                                    <div className="setting-label">Purchase Bills</div>
                                                    <div className="setting-input">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" id="access" />
                                                            <label className="form-check-label" for="access">
                                                                Unlimited
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <span className='checkbox-n-count'>
                                                        <span className='max-count'>
                                                            <span className='count'>Max Count</span>
                                                            <input className="sub-plan-input" type="text" value="646" />
                                                        </span>
                                                    </span>
                                                </div>
                                                <div className="setting-row">
                                                    <div className="setting-label">Users</div>
                                                    <div className="setting-input">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" id="access" />
                                                            <label className="form-check-label" for="access">
                                                                Unlimited
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <span className='checkbox-n-count'>
                                                        <span className='users d-flex'>
                                                            <div className="max-count-check form-check">
                                                                <input className="form-check-input" type="checkbox" value="" id="accessUser" />
                                                                <label className="form-check-label" for="accessUser">
                                                                    4 Users
                                                                </label>
                                                            </div>
                                                        </span>
                                                    </span>
                                                </div>
                                                {/* gst row */}
                                                <div className="setting-row gstIn">
                                                    <div className="setting-label">GSTIN</div>
                                                    <span className='checkbox-n-count'>
                                                        <span className='max-count'>
                                                            <span className='count'>Max Count</span>
                                                            <input className="sub-plan-input" type="text" value="646" />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                            Inventory
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <div className="settings-container">
                                                {/* plan detail row */}
                                                <div className="setting-row">
                                                    <div className="setting-label">Item groups</div>
                                                    <div className="setting-input">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" id="access" checked/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="setting-row">
                                                    <div className="setting-label">Composite items</div>
                                                    <div className="setting-input">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" id="access" checked/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="setting-row">
                                                    <div className="setting-label">Category</div>
                                                    <div className="setting-input">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" id="access" checked/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="setting-row">
                                                    <div className="setting-label">Reorder levels</div>
                                                    <div className="setting-input">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" id="access" checked/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="setting-row">
                                                    <div className="setting-label">Item images (Upto 15 images)</div>
                                                    <div className="setting-input">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" id="access" checked/>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="setting-row">
                                                    <div className="setting-label">Batch number tracking</div>
                                                    <div className="setting-input">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" id="access" checked/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="setting-row">
                                                    <div className="setting-label">Price list</div>
                                                    <div className="setting-input">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" id="access" checked/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="setting-row">
                                                    <div className="setting-label">Barcode generation</div>
                                                    <div className="setting-input">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" id="access" />
                                                        </div>
                                                    </div>
                                                </div>
                                               
                                                   


                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <SettingFooter /> */}
        </div>
    )
}
export default AddSubscription;