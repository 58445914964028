import React from 'react'
import SettingnavBar from '../../layout/SettingnavBar'
import TableView from '../../component/TableView/TableView'

const PlanDetails = () => {
  return (
    <div className="planDetails-wrapper">
        <SettingnavBar />
        <div className='planDetail-body'>
        <div className="ag-theme-quartz" style={{ height: 500 }}>
            <TableView />
    </div>
        </div>
    </div>
  )
}

export default PlanDetails
