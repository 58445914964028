import React, { useEffect, useRef, useState } from "react";
import './Signup.scss';
import signup from '../../../assets/image/signup_illustration.svg'
import InputField from "../../../component/form/FormInput";
import Button from "../../../component/ui/Button";
import { toast } from "react-toastify";
import FormSelect from "../../../component/form/FormSelect";
import { useNavigate } from "react-router-dom";
import { copyToClipboard, encodeEmailToToken, formatErrors, generateStrongPassword, objectCreater } from "../../../utils/controller";
import { number_prefix_dropdown } from "../../../utils/dropsdown";
import { master_data } from "../../../store/action/master";
import { useDispatch, useSelector } from "react-redux";
import { user_send_otp, user_signup } from "../../../store/action/auth";
import { Helmet } from 'react-helmet';
import flag from '../../../assets/image/flag.png'


const Signup = ( ) => {
  const [loader, setLoader] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isVisible, setisvisible] = useState(false)
  const [password, setPassword] = useState()
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');
  const [organisation, setOrganisation] = useState('');
  const [phone, setPhone] = useState('')
  const [error, setError] = useState({})
  const [checkTerms, setCheckTerms] = useState()
  let masterData = useSelector(state => state?.master?.masterData?.data)
  const countryList = objectCreater(masterData?.countries)
  const StateList = objectCreater(masterData?.states)
  const firstNameRef = useRef(null);
  useEffect(() => {
    if (firstNameRef.current) {
      firstNameRef.current.focus();
    }
  }, []);
  console.log(checkTerms)
  // master API call
  useEffect(() => {
    dispatch(master_data())
  }, [])

  const handleSignup = () => {
    setLoader(true)
    let body = {
      first_name: firstName,
      last_name: lastName,
      organization_name: organisation,
      email: email,
      password: password,
      country_id: country,
      state_id: state,
      phone_number: phone
    }
    if (checkTerms) {
      dispatch(user_signup(body)).then((res) => {
        if (res?.success) {
          localStorage.setItem('authToken', res?.token)
          if (res?.data?.is_email_verified === false) {
            const token = encodeEmailToToken(res?.data?.email)
            dispatch(user_send_otp({ email: res?.data?.email })).then((res) => {
              if (res?.success) {
                setLoader(false)
                toast.success(res?.message)
                navigate(`/otpVerify/?token=${token}`)
              } else {
                toast.error(res?.message)
                console.log(res?.message)
              }
            })
          }

        } else {
          console.log('res', res)
          toast.error(res?.message)
          let errors = formatErrors(res?.message);
          setError(errors)
          console.log(errors)
          setLoader(false)
        }
      })
    } else {
      toast.error('Please agree to the Terms of Services and Privacy Policy')
      setLoader(false)
    }
  }
  const handleGeneratePasswprd = () => {
    let generated_password = generateStrongPassword()
    setPassword(generated_password)
    setisvisible(!isVisible)
  }

  return (
    <div className='signup-form'>
      <Helmet>
        <title>Create your Free PharmNex account</title>
      </Helmet>
      <div className="signup-body row clearfix">
        <div className="left-section">
          <div className="illustration">
            <div className="brand-name"><span>Pharm</span><span>Nex</span></div>
            <p className="text-subtitle mb-5">
              <div className="text-center">Unlock the full potential of our innovation</div>
              <div className="text-center">Sign up now for exclusive access to our <span className="font-bold">pre-release version</span> </div>
            </p>

            <div className="signup-illustration"><img src={signup} alt="" /></div>
          </div>

        </div>
        <div className="right-section">
          <form className="form-body py-4 ms-0">
            <div className="d-flex justify-content-between align-items-baseline pb-4">
              <h1 className="form-title pb-0">Sign Up</h1>
              <p className="">Already have an account? <span className="primary-color cursor-pointer"><a href="/" className="font-bold"> Sign In</a></span></p>
            </div>

            <div className="row">
              <div className="col-6">
                <InputField
                  type="text"
                  value={firstName}
                  placeholder="First Name"
                  label="First Name"
                  onChange={(value) => setFirstName(value)}
                  error={error?.first_name}
                  required
                  autoFocus={true}
                  autoComplete={'First Name'}

                />
              </div>
              <div className="col-6">
                <InputField
                  type="text"
                  value={lastName}
                  placeholder="Last Name"
                  label="Last Name"
                  onChange={(value) => setLastName(value)}
                  error={error?.last_name}
                  required
                  autoComplete={'Last Name'}
                />
              </div>
              <div className="col-12">
                <InputField
                  type="text"
                  value={organisation}
                  placeholder="Enter your Organisation/Company name"
                  label="Organisation Name"
                  onChange={(value) => setOrganisation(value)}
                  error={error?.organization_name}
                  required
                  autoComplete={'Organisation Name'}
                />
              </div>
              <div className="col-12">
                <InputField
                  type="text"
                  value={email}
                  placeholder="Email Address"
                  label="Email/Username"
                  onChange={(value) => setEmail(value)}
                  error={error?.email}
                  required
                  autoComplete={'email'}
                />
              </div>
              <div className="col-12 position-relative">
                <InputField
                  type="text"
                  value={password}
                  placeholder="Create New Password"
                  label="Password"
                  onChange={(value) => setPassword(value)}
                  error={error?.password}
                  required
                  autoComplete={'password'}
                />
                {isVisible ? <span className="auto-genrated curser-pointer"><a className="font-bold text-decoration-none curser-pointer" onClick={() => copyToClipboard(password)}>Copy</a></span>
                  :
                  <span className="auto-genrated curser-pointer"><a className="font-bold text-decoration-none curser-pointer" onClick={handleGeneratePasswprd}>Auto-Generate</a></span>}
              </div>
              <div className="col-6">
                <FormSelect
                  label="Country"
                  placeholder="Country"
                  options={countryList}
                  onChange={(value) => setCountry(value)}
                  value={country}
                  error={error?.country}
                  required
                />
              </div>
              <div className="col-6">
                <FormSelect
                  label="State"
                  placeholder="State"
                  options={StateList}
                  onChange={(value) => setState(value)}
                  value={state}
                  error={error?.state}
                  required
                />
              </div>
              <div className="col-12 form-select-mobile">
                <label className="form-label" >Mobile Number</label>
                <div className="input-group mb-4 form-mobile-check">
                  <div className="input-group-prepend">
                    <div className="input-group-text p-0" style={{
                      minHeight: '4rem',
                      fontSize: '1.4rem',
                      marginRight: ".1rem",
                      minWidth: "5rem", borderTopRightRadius: '0', borderBottomRightRadius: "0", justifyContent: "center"
                    }}>
                      <img className="ms-3" src={flag} alt="flag" />
                      <span className="mx-3">+91</span>
                      {/* <FormSelect
                        label=""
                        placeholder="+91"
                        onChange={(value) => console.log(value)}
                        options={number_prefix_dropdown}
                        error={error?.state}
                        required
                      /> */}
                    </div>
                  </div>
                  <input style={{ borderLeft: '0' }} type="text" className="form-control" id="inlineFormInputGroup" placeholder="Mobile Number" onChange={(e) => setPhone(e?.target?.value)} value={phone} />
                </div>
              </div>
              <div className="col-12">
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" id="inlineCheckbox1" onChange={(e) => setCheckTerms(e?.target?.checked)} value={checkTerms} />
                  <p htmlFor="inlineCheckbox1" className="mb-4 form-check-label">I agree to the <span className="primary-color cursor-pointer"><a href="/signup" className="font-bold"> Terms of Services</a> and <a href="/signup" className="font-bold"> Privacy Policy</a></span></p>
                </div>

              </div>
              <div className="col-12" >

                <Button type="button" button="Create Your Account" onClick={handleSignup} loader={loader}>
                </Button>
              </div>


            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Signup;