import React, { useEffect, useRef, useState } from "react";

import './Login.scss';
import login from '../../../assets/image/login_illustration.svg'
import InputField from "../../../component/form/FormInput";
import Button from "../../../component/ui/Button";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { user_login, user_send_otp } from "../../../store/action/auth";
import { encodeEmailToToken, formatErrors, } from "../../../utils/controller";
import { master_data } from "../../../store/action/master";
import { Helmet } from 'react-helmet';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [inputValue, setInputValue] = useState({ email: '' });
  const [passValue, setPassValue] = useState({ password: '' });
  const { email } = inputValue;
  const { password } = passValue;
  const [loader, setLoader] = useState(false)
  const [error, setError] = useState({})
  const handleEmailChange = (inputValue) => {
    setInputValue({ email: inputValue });
  };
  const handlePasswordChange = (passValue) => {
    setPassValue({ password: passValue });
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoader(true)
    let body = {
      email: email,
      password: password
    }
    dispatch(user_login(body)).then((res) => {
      if (res?.success) {
        setLoader(false)
        console.log('res', res?.token)
        localStorage.setItem('authToken', res?.token)
        navigate('/app/dashboard')
      } else {
        console.log('Error', res)
        toast.error(res?.message)
        setLoader(false)
        let errors = formatErrors(res?.message)
        setError(errors)
      }
    })
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Helmet>
          <title>Login to Pharmnex</title>
        </Helmet>
        <div className='login-form'>
          <div className="login-body row clearfix">
            <div className="left-section" >
              <div className="illustration">
                <div className="brand-name"><span>Pharm</span><span>Nex</span></div>
                <div className="login-illustration"><img src={login} alt="" /></div>
              </div>

            </div>
            <div className="right-section">
              <form className="form-body py-4 ms-0">
                <h1 className="form-title">Login</h1>
                <InputField
                  value={email}
                  label="Email"
                  placeholder="Registered Email Address"
                  type="email"
                  onChange={handleEmailChange}
                  error={error?.email}
                  required={true}
                  name="email"
                  autoFocus={true}
                  autoComplete="email"
                  onKeyPress={handleKeyDown}
                />
                <InputField
                  value={password}
                  label="Password"
                  placeholder="Password"
                  type="password"
                  onChange={handlePasswordChange}
                  error={error?.password}
                  required={true}
                  name="password"
                  autoComplete="current-password"
                  onKeyPress={handleKeyDown}
                />
                <label className="form-forgotPass"><a href="/forgotPassword"> Forgot Password?</a></label>
              </form>
              <Button type="submit" button="Sign in"
                loader={loader}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Login;

