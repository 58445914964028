import React from 'react'
import { Outlet } from 'react-router-dom'
import SettingSideBar from './SettingSideBar'
import SettingnavBar from './SettingnavBar'
import SettingFooter from './SettingFooter'

const Settinglayout = () => {
  return (
    <div>
      {/* <SettingnavBar /> */}

      <div className='d-flex'>
        <div>
          <SettingSideBar />
        </div>
        <div style={{width:'calc(100% - 23.9rem)'}}>
          <Outlet />
        </div>
      </div>

      {/* <SettingFooter />            */}

    </div>

  )
}

export default Settinglayout