import React from 'react'
import './Layout.scss';
import search from '../assets/image/search-status.svg'
import profile from '../assets/image/userProfile.png'
import notification from '../assets/image/notification.svg'
import setting from '../assets/image/setting.svg'
import Button from '../component/ui/Button';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';
const Navbar = () => {

  const navigate = useNavigate()
  const handleSignout = () => {
    localStorage.clear();
    navigate("/")
  }
  const dateToFormat = '1976-04-19T12:59';

  return (
    <div className='navtop'>
      <div className='nav-container'>
        <div className="input-group form-mobile-check left-search w-auto">
          <div className="input-group-prepend">
            <div className="input-group-text p-0" style={{
              fontSize: '1.4rem',
              marginRight: ".1rem",
              marginLeft:".5rem",
              minWidth: "5rem", borderTopRightRadius: '0', borderBottomRightRadius: "0", justifyContent: "center"
            }}>
              <div className="dropdown text-end">
                <a href="#" className="d-block link-dark text-decoration-none dropdown-toggle px-3" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
                  <img src={search} alt="mdo" width="20" height="20" className="rounded-circle me-2" />
                </a>
                <ul className="dropdown-menu font-14" aria-labelledby="dropdownUser1" style={{ minWidth: '240px' }}>
                  <li><a className="dropdown-item" href="#">Settings</a></li>
                  <li><a className="dropdown-item" href="#">Profile</a></li>
                  <li><hr className="dropdown-divider" /></li>
                  <li><a className="dropdown-item" href="#">Sign out</a></li>
                </ul>
              </div>
            </div>
          </div>
          <form className="col-lg-auto">
            <input type="search" className="form-control" style={{ minWidth: '250px', borderLeft: '0', borderTopLeftRadius: '0', borderBottomLeftRadius: "0" }} placeholder="Search..." aria-label="Search" />
          </form>
        </div>
        <div className='right-details'>
          <span className='currentTime'><Moment local format="MMMM, DD, YYYY | h:mm A">{dateToFormat}</Moment></span>
          <span className='welcomeText'>Hey! <span className='currentName'>Welcome! Robert Jr</span></span>
          <div className='dropdown-action'>
            <a href="#" id="more-action" data-bs-toggle="dropdown" aria-expanded="false">
              <Button type="button" button="+" className="addBtn" />
            </a>
            <div className="dropdown-menu dropdown-menu-end" aria-labelledby="more-action" >
              <div className='d-flex'>
                <div className="col-4 more-actions-list">
                  <div className="section-title">GENERAL</div>
                  <ul className="action-menu">
                    <li className="action-menu-item">+ Item</li>
                    <li className="action-menu-item">+ Add Users</li>
                    <li className="action-menu-item">+ Items Groups</li>
                    <li className="action-menu-item">+ Inventory Adjustments</li>
                  </ul>
                </div>
                <div className="col-4 more-actions-list">
                  <div className="section-title">GENERAL</div>
                  <ul className="action-menu">
                    <li className="action-menu-item">+ Customer</li>
                    <li className="action-menu-item">+ Delivery Challan</li>
                    <li className="action-menu-item">+ Invoices</li>
                    <li className="action-menu-item">+ Retail Invoice</li>
                    <li className="action-menu-item">+ Sales Order</li>
                    <li className="action-menu-item">+ Shipment</li>
                    <li className="action-menu-item">+ Customer Payment</li>
                    <li className="action-menu-item">+ Credit Notes</li>

                  </ul>
                </div>
                <div className="col-4 more-actions-list">
                  <div className="section-title">GENERAL</div>
                  <ul className="action-menu">
                    <li className="action-menu-item">+ Vendor</li>
                    <li className="action-menu-item">+ Bills</li>
                    <li className="action-menu-item">+ Purchase Orders</li>
                    <li className="action-menu-item">+ Purchase Receives</li>
                    <li className="action-menu-item">+ Vendor Payment</li>
                    <li className="action-menu-item">+ Vendor Credits</li> 
                  </ul>
                </div>
              </div>

            </div>

            <ul className="dropdown-menu font-14" aria-labelledby="moreAction" style={{ minWidth: '240px' }}>
              <li><a className="dropdown-item" href="#">New project...</a></li>
              <li><a className="dropdown-item" href="#">Settings</a></li>
              <li><a className="dropdown-item" href="#">Profile</a></li>
              <li><hr className="dropdown-divider" /></li>
              <li><a className="dropdown-item" onClick={handleSignout}>Sign out</a></li>
            </ul>
          </div>

          <img src={notification} alt="mdo" width="25" height="25" />
          <img src={setting} alt="mdo" width="25" height="25" />

          <div className="dropdown text-end">
            <a href="#" style={{ padding: '4px 0' }} className="d-block link-dark text-decoration-none dropdown-toggle px-3" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
              <img src={profile} alt="mdo" width="32" height="32" className="rounded-circle me-2" />
            </a>
            <ul className="dropdown-menu font-14" aria-labelledby="dropdownUser1" style={{ minWidth: '240px' }}>
              <li><a className="dropdown-item" href="#">New project...</a></li>
              <li><a className="dropdown-item" href="#">Settings</a></li>
              <li><a className="dropdown-item" href="#">Profile</a></li>
              <li><hr className="dropdown-divider" /></li>
              <li><a className="dropdown-item" onClick={handleSignout}>Sign out</a></li>
            </ul>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Navbar;