import React, { useCallback, useMemo, useState, useRef, } from 'react'
import './TableView.scss'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
const TableView = () => {
    const [rowData, setRowData] = useState([
        { 'Plan Title': 'Creative Concoctions', 'Plan Type': 'Free', 'Monthly Cost': 15000, 'Annual Cost': 14000, Status: 'Enable' },
        { 'Plan Title': 'Kristin Watson', 'Plan Type': 'Free', 'Monthly Cost': 15000, 'Annual Cost': 14000, Status: 'Enable' },
        { 'Plan Title': 'Kathryn Murphy', 'Plan Type': 'Free', 'Monthly Cost': 15000, 'Annual Cost': 14000, Status: 'Enable' },
        { 'Plan Title': 'Robert Fox', 'Plan Type': 'Free', 'Monthly Cost': 15000, 'Annual Cost': 14000, Status: 'Disable' },
        { 'Plan Title': 'Robert ', 'Plan Type': 'Free', 'Monthly Cost': 15000, 'Annual Cost': 14000, Status: 'Enable' },
        { 'Plan Title': 'Robert Fox', 'Plan Type': 'Free', 'Monthly Cost': 15000, 'Annual Cost': 14000, Status: 'Enable' },
        { 'Plan Title': 'Creative 1', 'Plan Type': 'Free', 'Monthly Cost': 15000, 'Annual Cost': 14000, Status: 'Disable' },
        { 'Plan Title': 'Creative 2', 'Plan Type': 'Free', 'Monthly Cost': 15000, 'Annual Cost': 14000, Status: 'Enable' },
        { 'Plan Title': 'Creative', 'Plan Type': 'Free', 'Monthly Cost': 15000, 'Annual Cost': 14000, Status: 'Enable' },
        { 'Plan Title': 'Kathryn Murphy', 'Plan Type': 'Free', 'Monthly Cost': 15000, 'Annual Cost': 14000, Status: 'Enable' },
        { 'Plan Title': 'Fel', 'Plan Type': 'Free', 'Monthly Cost': 15000, 'Annual Cost': 14000, Status: 'Disable' },
        { 'Plan Title': 'Ramns ', 'Plan Type': 'Free', 'Monthly Cost': 15000, 'Annual Cost': 14000, Status: 'Enable' },
        { 'Plan Title': 'Flexin', 'Plan Type': 'Free', 'Monthly Cost': 15000, 'Annual Cost': 14000, Status: 'Disable' },
        { 'Plan Title': 'Loren Dev', 'Plan Type': 'Free', 'Monthly Cost': 15000, 'Annual Cost': 14000, Status: 'Enable' },
        { 'Plan Title': 'Namal J', 'Plan Type': 'Free', 'Monthly Cost': 15000, 'Annual Cost': 14000, Status: 'Enable' },
        { 'Plan Title': 'Crety F', 'Plan Type': 'Free', 'Monthly Cost': 15000, 'Annual Cost': 14000, Status: 'Enable' },
        { 'Plan Title': 'Creative', 'Plan Type': 'Free', 'Monthly Cost': 15000, 'Annual Cost': 14000, Status: 'Enable' },
        { 'Plan Title': 'Creative', 'Plan Type': 'Free', 'Monthly Cost': 15000, 'Annual Cost': 14000, Status: 'Enable' },

    ]);

    const [columnDefs, setColumnDefs] = useState([
        {
            field: "Plan Title",
            headerClass: 'HeaderBg',
            checkboxSelection: true,
            suppressSizeToFit: true,
            minWidth: 200,
            flex: 1
        },
        {
            field: "Plan Type",
            headerClass: 'HeaderBg',
            suppressSizeToFit: true,
            minWidth: 150,
            flex: 1
        },
        {
            field: "Monthly Cost",
            headerClass: 'HeaderBg',
            suppressSizeToFit: true,
            minWidth: 150,
            flex: 1
        },
        {
            field: "Annual Cost",
            headerClass: 'HeaderBg',
            suppressSizeToFit: true,
            minWidth: 150,
            flex: 1
        },
        {
            field: "Status",
            headerClass: 'HeaderBg',
            suppressSizeToFit: true,
            minWidth: 150,
            flex: 1
        }
    ]);

    return (
        <div className="ag-grid-table">
            <AgGridReact
                rowData={rowData}
                columnDefs={columnDefs}
                rowSelection="multiple"
                suppressRowClickSelection={true}
                pagination={true}
                paginationPageSize={false}
                paginationPageSizeSelector={false}
            />
        </div>
    )
}

export default TableView
