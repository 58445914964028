import { BrowserRouter, Routes, Route, RouterProvider } from "react-router-dom";
import './App.scss';
import { Approuter } from "./router";


const App = () => {
  return (
    <div className="wrapper">
      <div className="wrapper">
        <RouterProvider router={Approuter} />
      </div>
    </div>
  );
}

export default App

