import React from 'react'
import UnderCunstruction from '../underCunstruction'

const Dashboard = () => {
    return (
        <div>
            <UnderCunstruction prop={'Dashboard'} />
        </div>
    )
}

export default Dashboard



