 
import React from "react";
import { Navigate } from "react-router-dom";

// Example authentication check function
const isAuthenticated = () => {
  // Replace this with your actual authentication logic
  return !!localStorage.getItem("authToken");
};

const PrivateRoute = ({ element, ...rest }) => {
  return isAuthenticated() ? (
    React.cloneElement(element, { ...rest })
  ) : (
    <Navigate to="/" />
  );
};

export default PrivateRoute;
