import React from 'react'
import { Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link } from 'react-router-dom';

const SettingSideBar = () => {
    
    
    return (
        <div className='sub-leftside'>
            <span className='sub-left-items'>
                <div className="dropdown text-end">
                    <a href="#" className="d-block link-dark text-decoration-none dropdown-toggle px-3" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">

                        Search Settings

                    </a>
                    <ul className="dropdown-menu font-14" aria-labelledby="dropdownUser1" style={{ minWidth: '240px' }}>
                        <li><a className="dropdown-item" href="#">All Plan</a></li>
                        <li><a className="dropdown-item" href="#">Acitive Plans</a></li>
                        <li><a className="dropdown-item" href="#">Inacitive Plans</a></li>
                        <li><a className="dropdown-item" href="#">Free Plans</a></li>
                        <li><a className="dropdown-item" href="#">Acitive Plans</a></li>
                        <li><a className="dropdown-item" href="#">Paid Plans</a></li>
                    </ul>
                </div>
                <Menu className='setting-side-menu'>
                <SubMenu defaultOpen label="Subscription Plans">
                    <MenuItem className='setting-menu-item active'> <Link to="/app/setting/subscription" >Plan</Link> </MenuItem>
                    <MenuItem className='setting-menu-item'> Plan Discount </MenuItem>
                </SubMenu>
                </Menu>
                
            </span>
        </div>        
    )
}

export default SettingSideBar