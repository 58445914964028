import React from 'react'
import './Subscription.scss';
import AddSubscription from './AddSubscription/AddSubscription'

const Subscription = () => {
    return (
        <div>
            <AddSubscription />
        </div>
    )
}
export default Subscription;