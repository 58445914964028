// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-control {
  min-height: 4rem;
  font-size: 1.4rem;
}

.form-label {
  font-size: 1.4rem;
  font-family: var(--PlusJakartaSans-Medium) !important;
}

.btn {
  min-height: 4rem;
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}

.btn-primary {
  --bs-btn-bg: #3B58FF !important;
  --bs-btn-border-color: #3B58FF;
  --bs-btn-hover-bg: #314ad7;
  --bs-btn-active-bg: #314ad7;
  --bs-btn-active-border-color: #314ad7;
  --bs-btn-hover-border-color: #314ad7;
  --bs-btn-focus-shadow-rgb: 49, 74, 215;
  --bs-btn-disabled-bg: #3B58FF;
  --bs-btn-disabled-border-color: #3B58FF;
}

.cursor-pointer {
  cursor: pointer;
}

.css-1u9des2-indicatorSeparator {
  display: none !important;
}

.css-15lsz6c-indicatorContainer,
.css-1xc3v61-indicatorContainer {
  padding-left: 0 !important;
}

div#react-select-7-placeholder {
  font-size: 1.4rem;
}

.inpuBG {
  border-radius: 8px;
  border: 1px solid rgba(85, 91, 103, 0.15);
  background-color: #FCFCFC;
}

.dropdown-menu.show {
  border-radius: 0.8rem !important;
}`, "",{"version":3,"sources":["webpack://./src/assets/global.scss"],"names":[],"mappings":"AACA;EACI,gBAAA;EACA,iBAAA;AAAJ;;AAEA;EACI,iBAAA;EACA,qDAAA;AACJ;;AACA;EACI,gBAAA;AAEJ;AADI;EACI,gCAAA;EACA,wCAAA;EACA,8CAAA;AAGR;;AACA;EACI,+BAAA;EACA,8BAAA;EACA,0BAAA;EACA,2BAAA;EACA,qCAAA;EACA,oCAAA;EACA,sCAAA;EACA,6BAAA;EACA,uCAAA;AAEJ;;AAAA;EACI,eAAA;AAGJ;;AAAA;EACI,wBAAA;AAGJ;;AAAE;;EAEE,0BAAA;AAGJ;;AADE;EACE,iBAAA;AAIJ;;AAAA;EACI,kBAAA;EACA,yCAAA;EACA,yBAAA;AAGJ;;AAEA;EACI,gCAAA;AACJ","sourcesContent":["// global css\n.form-control {\n    min-height: 4rem;\n    font-size: 1.4rem;\n}\n.form-label{\n    font-size: 1.4rem;\n    font-family: var(--PlusJakartaSans-Medium) !important;\n}\n.btn {\n    min-height: 4rem;\n    &:hover {\n        color: var(--bs-btn-hover-color);\n        background-color: var(--bs-btn-hover-bg);\n        border-color: var(--bs-btn-hover-border-color);\n    }\n}\n\n.btn-primary {\n    --bs-btn-bg: #3B58FF !important;\n    --bs-btn-border-color: #3B58FF;\n    --bs-btn-hover-bg: #314ad7;\n    --bs-btn-active-bg: #314ad7;\n    --bs-btn-active-border-color: #314ad7;\n    --bs-btn-hover-border-color: #314ad7;\n    --bs-btn-focus-shadow-rgb: 49, 74, 215;\n    --bs-btn-disabled-bg: #3B58FF;\n    --bs-btn-disabled-border-color: #3B58FF;    \n}\n.cursor-pointer{\n    cursor:pointer;\n}\n//  for select field\n.css-1u9des2-indicatorSeparator {\n    display: none !important;\n  }\n  \n  .css-15lsz6c-indicatorContainer,\n  .css-1xc3v61-indicatorContainer {\n    padding-left: 0 !important;\n  }\n  div#react-select-7-placeholder {\n    font-size: 1.4rem;\n}\n\n// input field\n.inpuBG{\n    border-radius: 8px;\n    border: 1px solid rgba(85, 91, 103, 0.15);\n    background-color: #FCFCFC;\n}\n\n//for dropdown\n\n.dropdown-menu.show {\n    border-radius: .8rem !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
