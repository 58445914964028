import React from 'react'
import Button from '../component/ui/Button'

const SettingnavBar = () => {
    return (
            <div className='nav-header'>
                <div className='nav-container'>
                    <span>Add New Subscription Plan</span>
                    <Button type="button" button="X" className="sub-detail-close" />
                </div>
            </div>
    )
}

export default SettingnavBar