import React, { useState } from "react";
import Select from 'react-select';

const options_select = [
  { value: 'option', label: 'option' },
];
const FormSelect = ({ label,placeholder,options,onChange,disabled,name,error}) => { 
  const [selectedOption, setSelectedOption] = useState(null);
  const handleChange = (e) => {
    onChange(e.value);
  };
  return (
    <div className="form-group mb-lg-4 mb-md-3 mb-sm-3 mb-3">
      {label && <label className="form-label" htmlFor="select-field">{label}</label>}
      <Select
          defaultValue={selectedOption}
          onChange={handleChange}
          options={options || options_select}
          placeholder={placeholder}
          disabled = {disabled}
          name={name}
        />
      {error && <div className="error-text" style={{ color: 'red' ,fontSize:"12px",marginBottom:"10px"}}>{error}</div>}
    </div>
  );
};

export default FormSelect;