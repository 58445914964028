import { configureStore } from '@reduxjs/toolkit'
import { masterReducer } from '../reducers/master'
// import { counterReducer } from '../action/auth'



export default configureStore({
  reducer: {
    master: masterReducer
  }
})