// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ag-grid-table {
  height: calc(100vh - 14.5rem);
}
.ag-grid-table .HeaderBg {
  background: #DADDEC;
}`, "",{"version":3,"sources":["webpack://./src/component/TableView/TableView.scss"],"names":[],"mappings":"AAAA;EACI,6BAAA;AACJ;AACI;EAGJ,mBAAA;AADA","sourcesContent":[".ag-grid-table{\n    height: calc(100vh - 14.5rem);\n\n    .HeaderBg{\n        // border: 1px solid #C3CBDC;\n\nbackground: #DADDEC;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
