import React from 'react'
import Button from '../component/ui/Button'

const SettingFooter = () => {
  return (
    

//     <div className='nav-header'>
//     <div className='nav-container'>
//         <span>Add New Subscription Plan</span>
//         <Button type="button" button="X" className="sub-detail-close" />
//     </div>
// </div>


      <div className='setting-footer'>
                <div className='d-flex gap-4 ms-4 w-100'>
                    <Button type="button" button="Save" className="setting-btn-padding-x" />
                    <Button type="button" button="Cancel" className="setting-btn-padding-x btn-dark setting-cancel" />
                </div>
            </div>
    
  )
}

export default SettingFooter
