// import React, { useState } from "react";

// const Button = ({ button, type, onClick, loader ,disabled}) => {
//   // console.log("button", button)

//   return (
//     <div>
//       <button type={type} className="w-100 btn btn-lg btn-primary" onClick={onClick} disabled = {disabled}>{loader ? <i className="fa fa-refresh fa-spin"></i> : button}</button>
//     </div>
//   )
// };

// export default Button;
import React, { useEffect } from "react";

const Button = ({ button, type, onClick, loader, disabled, className }) => {
  return (
    <div>
      <button
        type={type}
        className={`w-100 btn btn-lg btn-primary ${className}`}
        onClick={onClick}
        disabled={disabled}
      >
        {loader ? <i className="fa fa-refresh fa-spin"></i> : button}
      </button>
    </div>
  );
};

export default Button;
