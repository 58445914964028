 
// import React from 'react';
// import PropTypes from 'prop-types';

// const InputField = ({ value, label, placeholder, type, onChange, error, required ,name}) => {
//   const handleChange = (e) => {
//     const { value } = e.target;
//     onChange(value);
//   };

//   return (
//     <div className="form-group">
//       {label && (
//         <label className="form-label" htmlFor="input-field">
//           {label} {required && <span style={{ color: 'red' }}>*</span>}
//         </label>
//       )}
//       <input
//         type={type}
//         value={value}
//         className={`form-control mb-lg-4 mb-md-3 mb-sm-3 mb-3 ${error ? 'border-red' : ''}`}
//         placeholder={placeholder}
//         onChange={handleChange}
//         name = {name}
//       />
//       {error && <div className="error-text" style={{ color: 'red' ,fontSize:"12px",marginTop:"-10px",marginBottom:"10px"}}>{error}</div>}
//     </div>
//   );
// };

// InputField.propTypes = {
//   value: PropTypes.string.isRequired,
//   label: PropTypes.string,
//   placeholder: PropTypes.string,
//   type: PropTypes.string,
//   onChange: PropTypes.func.isRequired,
//   error: PropTypes.string,
//   required: PropTypes.bool,
// };

// InputField.defaultProps = {
//   label: '',
//   placeholder: '',
//   type: 'text',
//   error: '',
//   required: false,
// };

// export default InputField;
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const InputField = ({ value, label, placeholder, type, onChange, error, required, name, autoFocus, autoComplete,onKeyPress  }) => {
  const inputRef = useRef(null);

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [autoFocus]);

  const handleChange = (e) => {
    const { value } = e.target;
    onChange(value);
  };

  return (
    <div className="form-group">
      {label && (
        <label className="form-label" htmlFor={`input-field-${name}`}>
          {label} {required && <span style={{ color: 'red' }}>*</span>}
        </label>
      )}
      <input
        type={type}
        value={value}
        className={`form-control mb-lg-4 mb-md-3 mb-sm-3 mb-3 inpuBG ${error ? 'border-red' : ''}`}
        placeholder={placeholder}
        onChange={handleChange}
        name={name}
        ref={inputRef}
        id={`input-field-${name}`}
        autoComplete={autoComplete}
        onKeyPress={onKeyPress}
      />
      {error && (
        <div
          className="error-text"
          style={{ color: 'red', fontSize: '12px', marginTop: '-10px', marginBottom: '10px' }}
        >
          {error}
        </div>
      )}
    </div>
  );
};

InputField.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  required: PropTypes.bool,
  name: PropTypes.string.isRequired,
  autoFocus: PropTypes.bool,
  autoComplete: PropTypes.string,
};

InputField.defaultProps = {
  label: '',
  placeholder: '',
  type: 'text',
  error: '',
  required: false,
  autoFocus: false,
  autoComplete: 'on',
};

export default InputField;
